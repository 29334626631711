.contact-form-container {
    /* Example border color */
   
   color:#fff
  
 }
 .contact-container {
   border: 1px solid #fff; 
   border-radius: 0.5rem;
   width: 40px;
   
  
   
 }

 .res1 iframe{
  display: none;
 }
 .re{
   text-align: center;
 }

 .r{
   background-color:#FF7700;
   border-radius: 40px;
  width: 1200px;
  margin-left: 30px;
  height: 500px;
 }
 
.a{
 position: relative;
 display: flex;
}
 .a12{
   margin-left: 150px;
   padding-bottom: 40px;

 }

 .a13{
   margin-left: 100px;
   padding-bottom: 40px;
 }


 

 .a14{
   margin-left: 100px;
   padding-bottom: 40px;
 }

 .a15{
   margin-left: 100px;
   padding-bottom: 40px;
 }
 .a12 h2{
   color: #131E3A;
 }


 .a12 h6{
   color: #131E3A;
 }

 .a13 h2{
   color: #131E3A;
 }

 .global h1{
   color: #131E3A;
 }

 .a13 h6{
   color: #131E3A;
 }


 .a14 h2{
   color: #131E3A;
 }


 .a14 h6{
   color: #131E3A;
 }



 .a15 h2{
   color: #131E3A;
 }


 .a15 h6{
   color: #131E3A;
 }




 .sol11{
   display: flex;
   position: relative;
  
   border-radius: 30px;
   height: 400px;
   width: 1300px;
   margin-left: 30px;
   padding-top: 40px;
}




.r12{
 display: flex;
 position: relative;
}

.r12 img{
  width: 100px;
}

.r121 h4{
 color: #131E3A;
}
.r122 h4{
 margin-left: 110px;
 color: #131E3A;
}
.r122 h6{
 margin-left: 60px;
 color: gray;
}

.r123 h4{
 margin-left: 100px;
 color: #131E3A;
 margin-top: 10px;
}
.r123 h6{
 margin-left: 0px;
 
 color: gray;
 
 max-width: 250px;
 text-align: justify;
}

.r123{
  margin-left: 30px;
}
.r122 img{
 margin-left: 90px;
}

.r123 img{
 margin-left: 100px;
 margin-top: 10px;
 width: 80px;
}
.leftsol1{

  color: gray;
   margin-left: 30px;
   margin-top: 30px;
}

.right1 img{
   width: 500px;
   height: 300px;
   border-radius: 30px;
   margin-top: 50px;
   margin-left: 20px;
   
  
   
}

.leftsol1 h1{
margin-left: 44px;
font-weight: bolder;
color: #131E3A;
}

.connect1{
   margin-left: 50px;
}








@media screen and (max-width: 767px){
  .res1 iframe{
    display: block;
   width: auto;
   border-radius: 20px;
   margin-top: 20px;
   margin-left:30px;
   height:300px
  }

 .right1 img{
  display: none;
 }
 .r{
  width:auto;
 
  margin: 10px;

 }
 .ress iframe{
  display:none;

 }

 .leftsol1 h1{
  
  margin-left: -40px;
 }
 .r12 {
  flex-direction: column;
  align-items: center;
  margin-left: -45px;
}

.r12 div {
  margin: 10px;
 
  
}
.sp{
  padding: 130px;
}
 
 
}







 
 