
.g-1{
    display: flex;
    flex-direction: row;
}

.g-1 img{
    width: 380px;
    padding: 10px;


}


.image-texts{
    text-align: center;
}
.g-4 img{
    width: 380px;
    padding: 10px;

}



.g-2{
    display: flex;
    flex-direction: row;
}

.g-2 img{
    width: 380px;
    padding: 10px;


}

.g-3{
    display: flex;
    flex-direction: row;
}

.g-3 img{
    width: 380px;
    

}

.g-2{
    margin-left: 0px;
}
.g-5{
    display: flex;
    flex-direction: row;
}

.g-5 img{
    width: 380px;
    padding: 10px;

}

.g-4{
    display: flex;
    flex-direction: row;
}

.g-all{
   display: flex;
   flex-wrap: wrap;
   justify-content: center;


}

.products1{
    font-size: '50px';
    text-align: 'center';
}


.image-container img{
    border: solid;

    

}



@media only screen and (max-width: 767px) {

    .products1{
        font-size:'30px';
        text-align: center;
        margin-left: -70px;
    }


    .g-all{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
        
     
     }
    .g-1{
        display: flex;
        flex-direction: column;
        
    }
    
 .image-container img{
    width: 150px;
    
 }

 .g-2{
    display: flex;
    flex-direction: column;
 }
 .g-3{
    display: flex;
    flex-direction: column;
    
 }

 .g-2 img{
    margin-left: -50px;
 }

 .g4{
    display: flex;
    flex-direction: column;
    
 }
 .g5{
    display: flex;
    flex-direction: column;
    
 }



   
 




}


