/* CarouselFadeExample.css */
.zooming-image img {
    width: 100%;
    height: auto;
    
    

    transition: transform 0.5s ease;
  }
  
  .zooming-image img.active {
    transform: scale(1.1);
  }
  