.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{
    width: 50px;
    height: 50px;
    font-size: 54px !important;
    background-color: rgb(89, 85, 78) !important;
    border-radius: 50%;
    opacity: 0.8;
}
.owl-theme .owl-nav{
    text-align: unset;
}
.owl-carousel .owl-nav button.owl-prev{
    position: absolute;
    left: 10px;
    top:121px
}
.owl-carousel .owl-nav button.owl-next{
    position: absolute;
    right:10px;
    top:121px

}
.owl-carousel .owl-nav button.owl-prev span,.owl-carousel .owl-nav button.owl-next span{
    display: block;
    margin-top: -20px;
}

.team-member-card {
    .overlay{
      position: absolute;
      top: 0;
      left: 0;
      
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  
    &:hover .overlay {
      opacity: 1;
      
    }
  }

  .bgimg{
    background-image: url('../../public/bgimg.jpg');
    width: 100%;
    background-attachment: fixed;
    height:500px
  }
  .cont-box{
  
    height:250px;
    background-color:#E85831;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
;
  }

  @media (max-width: 767px) {
    /* Adjust styles for smaller devices */
    .bgimg {
      height: auto; /* Allow the height to adjust based on content */
    }
    
    .cont-box {
      margin-top: 20px; /* Add some spacing for smaller devices */
    }
  }